/* eslint-disable no-param-reassign */
import { SuggestionFilterParamsType, MetricsFilter, AdTypeKeywordType } from './type';
import { filterPercentKeys } from './enum';

export const MetricsFilters: string[] = [
  // 'units_sold_14d', // 广告销量 该字段没用上
  'amt_ad_14d', // 广告销售额
  'sum_spend', // 花费
  'acos_14d',
  'target_acos',
  'roas_14d',
  'sum_clicks', // 点击量
  'amt_ad_direct_14d', // 直接销售额
  'cpc', // 点击成本
  'sum_impressions', // 曝光量
  'cvr_14d', // 转化率
  'ctr', // 点击率
  'cnt_ad_14d', // 广告订单量
  'campaign_daily_budget', // 每日预算
];

export const IsInMetric = (key: string): boolean => MetricsFilters.some((m) => key.indexOf(m) > -1);

const setMetricsFilter = (filter: MetricsFilter, key: string, data: SuggestionFilterParamsType): void => {
  const oriKey = key.slice(0, -2);
  const index = key.slice(-1);

  if (!filter[oriKey]) {
    filter[oriKey] = {};
  }

  if (index === '1') {
    filter[oriKey].min = filterPercentKeys.includes(oriKey) ? Number(data[key])/100 : Number(data[key]);
  }
  if (index === '2') {
    filter[oriKey].max = filterPercentKeys.includes(oriKey) ? Number(data[key])/100 : Number(data[key]);
  }
}

const getAdKeywordTypes: (key: string, data: SuggestionFilterParamsType) => AdTypeKeywordType = (key, data): AdTypeKeywordType => {
  const adKeywordVal = data[key].split('_');
  let resData: AdTypeKeywordType = {};

  if (adKeywordVal.length > 1) {
    const ad_type = adKeywordVal[0];
    const keyword_type = adKeywordVal[1];

    resData = {
      ad_type,
      keyword_type
    }
  } else {
    const keyword_type = adKeywordVal[0];

    resData = {
      keyword_type
    }
  }

  return resData;
}

export const makeSuggestionFilterData = (formData: Record<string, any>): SuggestionFilterParamsType => {
  const filterRes: SuggestionFilterParamsType = {
    rule_type: formData.rule_type,
  };
  const metricsFilters: MetricsFilter = {};

  Object.keys(formData).forEach((key: string) => {
    if (formData[key]) {
      if (IsInMetric(key)) {
        setMetricsFilter(metricsFilters, key, formData);
      } else if (key === 'ad_type_keyword_types') {
        const resData = getAdKeywordTypes(key, formData);
        filterRes[key] = [resData];
      } else {
        filterRes[key] = formData[key] instanceof Array ? formData[key] : [formData[key]];
      }
    }
  });

  if (Object.keys(metricsFilters).length > 0) {
    filterRes.metrics_filters = metricsFilters;
  }

  return filterRes;
};

export const formatPrice = (value: any) => {
  if (!value) {
    return 0
  }
  const [integerPart, decimalPart] = value.toString().split('.');
  // 格式化整数部分并添加逗号
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // 如果有小数部分则保留，否则只返回整数部分
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};
