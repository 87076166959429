/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useEffect, useState } from 'react';
import { Table, Pagination, message, Space, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import URI from 'urijs';
import {
  styled,
} from '@superset-ui/core';
import type { ColumnsType } from 'antd/es/table';
import { MONTH_MONEY, YEAR_MONEY } from 'src/pages/Pricing/constant';
import { formatPrice } from 'src/pages/Adtopic/component/RightContent/Suggestion/utils';
import { transformMoney } from 'src/pages/Pricing/OrderInfo';
import { getMyOrderList, cancelMyOrder, getMySubscript } from './api';
import styles from './index.module.less';
import './index.less';

export type MySubscriptLevel = 'FREE' | 'ADVANCED' | 'ENTERPRISE';

export interface SubProp {
  description: string
  duration: 'MONTHLY' | 'YEARLY'
  level: MySubscriptLevel
  quantity: number
}

export type OrderStatusType = 'PAYING' | 'PROCESSING' | 'CANCEL' | 'FINISHED'

interface DataType {
  key: number;
  amount: number
  code_url: string | null
  created_at: string
  finished_at: string | null
  id: string
  payed_at: string | null
  payment_method: string | null
  status: OrderStatusType
  subscription_items: Array<SubProp>
  transaction_id: string | null
}

export const levelMap = {
  FREE: '普通版',
  ADVANCED: '高级版',
  ENTERPRISE: '定制版',
}

export const priceMap = {
  MONTHLY: MONTH_MONEY,
  YEARLY: YEAR_MONEY,
}

export const statusMap = {
  PAYING: '支付中',
  PROCESSING: '已支付',
  CANCEL: '已取消',
  FINISHED: '已完成',
}

const OrderList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalNum, setTotalNum] = useState(0);
  const [loading, setLoading] = useState(false);

  const payHandle = (record: DataType) => {
    const { amount, code_url, subscription_items } = record;
    const d = subscription_items[0];
    const queryParams = {
      ...d,
      amount,
      code_url,
      order_id: record.id,
      price: d.quantity * priceMap[d.duration]
    }

    const encodeParams = encodeURIComponent(URI.buildQuery(queryParams));
    
    window.open(`/pricing/?${encodeParams}`, '_blank');
  }

  // eslint-disable-next-line theme-colors/no-literal-colors
  const CustomTable = styled(Table)`
    .ant-table-thead > tr > th {
      background: #f7f8fd;
    }
  `

  const cancelHandle = async (orderId: string) => {
    try {      
      await cancelMyOrder(orderId);
      message.success('已成功取消订单');
      requestOrderList();
    } catch (error) {
      message.error('内部服务出现问题， 请联系客服人员')
    }
  }

  const columns:ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
    },
    {
      title: '套餐',
      dataIndex: 'subscription_items',
      render: (items:DataType['subscription_items']) => items && items.length > 0 ? levelMap[items[0].level] : '--',
    },
    {
      title: '原价',
      dataIndex: 'origin_price',
      render: (_:any) => _ || '--'
    },
    {
      title: '优惠金额',
      dataIndex: 'save_money',
      render: (_:any) => _ || '--'
    },
    {
      title: '实付',
      dataIndex: 'amount',
      // eslint-disable-next-line theme-colors/no-literal-colors
      render: (_: number) => <span style={{color: '#F90C0C'}}>{`$${formatPrice(transformMoney(String(_)))}`}</span>,
    },
    {
      title: '购买时长',
      dataIndex: 'subscription_items',
      render: (_: DataType['subscription_items']) => {
        const item = _[0];
        const unit = item.duration === 'YEARLY' ? '年' : '个月';
        return `${item.quantity}${unit}`;
      },
    },
    {
      title: '下单时间',
      dataIndex: 'payed_at',
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{dayjs(text).format('YYYY-MM-DD HH:mm')}</div>;
      },
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      render: (_: OrderStatusType) => statusMap[_] || '--'
    },
    {
      title: '备注',
      dataIndex: 'subscription_items',
      render: (items:DataType['subscription_items']) => items && items.length > 0 ? items[0].description : '--',
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_:any, record: DataType) => (
        <Space size={12}>
          {
            record.status === 'PAYING' &&  <div className={styles.g_flex_global}>
            <span className={styles.pay_btn} onClick={() => payHandle(record)}>支付</span> | 
            <Popconfirm
              title="您确定要取消正在支付中的订单么?"
              onConfirm={() => cancelHandle(record.id)}
              okText="确定"
              cancelText="取消"
            >
              <span className={styles.cancel_btn}>取消</span>
            </Popconfirm>
          </div> || '--' || '--'
          }
        </Space>
      ),
    }
  ]

  const pageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize)
  };

  const requestOrderList = async () => {
    const params = {
      page: currentPage,
      page_size: pageSize
    }
    setLoading(true);
    try {
      const res = await getMyOrderList(params);
      const {ret, pagination} = res.json;
      const dataSource = ret.map((i: DataType, index:number) => ({
        ...i,
        key: index + 1
      }));

      setDataSource(dataSource);
      setTotalNum(pagination.element_total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    requestOrderList();
    getMySubscript();
  }, [currentPage, pageSize])

  return (
    <div className={styles.my_order_container}>
      <div className={styles.my_order_title}>我的订单</div>

      <CustomTable
        rowKey={(record: DataType) => record.key}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      {dataSource.length === 0 ? (
        <></>
      ) : (
        <div className="table-pagination" style={{ margin: '15px 0 25px 0px' }}>
          <Pagination
            current={currentPage}
            onChange={pageChange}
            pageSize={pageSize}
            total={totalNum}
          />
        </div>
      )}
    </div>
  )
}

export default memo(OrderList);
